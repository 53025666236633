import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/DocsLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3>{`Prerequisites`}</h3>
    <ul>
      <li parentName="ul">{`An account on the smallstep platform. Need one? `}<a parentName="li" {...{
          "href": "https://smallstep.com/signup?product=ssh"
        }}>{`Register here.`}</a></li>
    </ul>
    <h3>{`Features`}</h3>
    <p>{`The following features are supported:`}</p>
    <ul>
      <li parentName="ul">{`Client configuration of OpenSSH to support Smallstep SSH`}</li>
      <li parentName="ul">{`Support for the following client platforms:`}
        <ul parentName="li">
          <li parentName="ul">{`macOS (10.13 High Sierra or above)`}</li>
          <li parentName="ul">{`Windows 10 (using PowerShell)`}</li>
          <li parentName="ul">{`Ubuntu 18.04 LTS`}</li>
          <li parentName="ul">{`CentOS 7 and CentOS Stream 8`}</li>
          <li parentName="ul">{`Fedora (34 and 35)`}</li>
          <li parentName="ul">{`Debian 10`}</li>
        </ul>
      </li>
    </ul>
    <h2>{`Instructions`}</h2>
    <p>{`The Client Quickstart is accessible within the smallstep UI. When selected, it configures a unique URL that simplifies user registration down to a simple copy-paste exercise. You can find it as follows:`}</p>
    <h3>{`Sign in to the smallstep UI`}</h3>
    <ul>
      <li parentName="ul">{`Sign in at `}<inlineCode parentName="li">{`https://smallstep.com/app/[Team ID]`}</inlineCode></li>
      <li parentName="ul">{`Select the "Resources" menu item`}</li>
      <li parentName="ul">{`A link to the `}<strong parentName="li">{`Client Quickstart Guide`}</strong>{` is available under the "Guides" section of this page`}</li>
    </ul>
    <h3>{`Alternate Instructions`}</h3>
    <p>{`You can also modify the following link by replacing `}<inlineCode parentName="p">{`[Team ID]`}</inlineCode>{` with your Smallstep SSH Team ID:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`https://smallstep.com/app/teams/quickstart?team=[Team ID]`}</inlineCode></li>
      <li parentName="ul">{`For example: smallstep.com/app/teams/quickstart?`}<strong parentName="li">{`team=avengers`}</strong></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      